const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Upload Settlement',
      //   buttonRef: 'uploadSettlementBtn',
      //   variant: 'info',
      // },
    ],
    tableStatusFilterButton: [
      // {
      //   buttonLabel: 'Pending',
      //   buttonRef: 'pendingSettlementFilter',
      //   active: true,
      // },
      // {
      //   buttonLabel: 'Completed',
      //   buttonRef: 'completedSettlementFilter',
      //   active: false,
      // },
    ],
    buttons: {

      refresh: true,
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'merchant_id', text: 'Merchant ID' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Commission',
        view: 'View Commission',
        remove: 'Remove Commission',
        create: 'Create New Commission',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [

        {
          enable: false, param: 'id', btnLabel: 'Disburse', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'disburseCommission', disableCondition: 'status:COMPLETED',
        },
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewReferral', disableCondition: 'status:TEST',
        },
        {
          enable: false, param: 'report_id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-commissions-datatable-details',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
          // pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        //   "commission_rate_type": null,
        //   "payout_service_id": null,
        //   "is_complete_profile": false,
        //   "transactions_count": "1000",
        //   "account_id": 77,
        //   "user_id": 98,
        //   "name": "WIRA AZHARAN AKMAR",
        //   "cashback_value": "20",
        //   "record_status": 1,
        //   "affiliate_uuid": "a413be82-f263-419e-8d81-417ad6190e13",
        //   "is_cashback": false,
        //   "created_at": "2024-07-22T19:18:54",
        //   "id": 35,
        //   "nric": "01223604445",
        //   "affiliate_link": null,
        //   "updated_at": "2024-07-22T19:18:54",
        //   "email": "wir.a.azharan@leanis.com.my",
        //   "account_number": null,
        //   "affiliate_promo_link": "https://stag-onboard.leanpay.my/register/zBUSI0",
        //   "affiliate_code": "zBUSI0",
        //   "account_bank_name": null,
        //   "affiliate_total_amount": 0,
        //   "commission_rate": null,
        //   "is_payout": false
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },

        {
          key: 'id', label: 'Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'account_id', label: 'Account Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'email', label: 'Email', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'nric', label: 'NRIC/Passport', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'is_cashback', label: 'Cashback?', sortable: true, visible: false, type: 'boolFlag', showInDetails: true,
        },
        {
          key: 'cashback_value', label: 'Cashback Value', sortable: true, visible: false, type: 'monetary', showInDetails: true,
        },
        {
          key: 'affiliate_link', label: 'Affiliate Link', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'affiliate_uuid', label: 'Affiliate UUID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'affiliate_code', label: 'Affiliate Code', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'affiliate_promo_link', label: 'Link', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'account_bank_name', label: 'Bank', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'payout_service_id', label: 'Payout Service ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'account_number', label: 'Account Number', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'commission_rate', label: 'Commission Rate', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'affiliate_total_amount', label: 'Total Amount', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'id', label: 'Id', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, class: 'text-right', type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
