<template>
  <div>
    <lp-datatable
      ref="CommissionReferralRef"
      title="Commission Referral"
      store-module="commissions"
      store-data-endpoint="getCommissionReferralList"
      store-getter-endpoint="getCommissionReferralList"
      pagination-state-key="commissionReferralList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="merchant_id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
    />
  </div>
</template>

<script>
import humaniseMixin from '@/common/humanise.mixin'
import Config from '@/views/administration/commissionReferrals/formConfig'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      referrals: [],
      extraParam: {
        invoice_status: [
          'PENDING',
        ],
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Commission Referrals' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    async actionBtnClicked(e) {
      if (e.actionName === 'viewReferral') {
        await this.$router.push({ name: 'administration-commission-referral-details', params: { id: e.rowData.id } })
      }
    },
  },
}
</script>
